document.map_poi_position = 0;
document.map_poi_markers = [];
document.window_resized_resize_photos = function () {
	if (window.innerWidth >= 1081){
		$(".photo_pad").width(((window.innerWidth - 962)/2) - 14);
		$(".hover_left").width(((window.innerWidth - 962)/2) - 14);
		$(".hover_right").width(((window.innerWidth - 962)/2) - 14);
	}else if (window.innerWidth <= 	1080 && window.innerWidth > 765){
		$(".photo_pad").width(((window.innerWidth - 680)/2) - 14);
		$(".hover_left").width(((window.innerWidth - 680)/2) - 14);
		$(".hover_right").width(((window.innerWidth - 680)/2) - 14);
	}else if (window.innerWidth <= 	765 && window.innerWidth > 541){
		$(".photo_pad").width(((window.innerWidth - 500)/2) - 22);
		$(".hover_left").width(((window.innerWidth - 500)/2) - 22);
		$(".hover_right").width(((window.innerWidth - 500)/2) - 22);
		$(".youtube_video_snap").height("");
		$(".inner_youtube_video_snap").height("");
	}else if (window.innerWidth <= 541 && window.innerWidth > 0){
		$(".photo_pad").width(0);
		$(".hover_left").width(0);
		$(".hover_right").width(0);
		var photo_height = $(".listing_photo_snap")[0].height
		$(".youtube_video_snap").height("" + photo_height + "px");
		$(".inner_youtube_video_snap").height("" + photo_height + "px");
	}

}
function move_photos_right() {
	$(".image_carousel_snap").scrollLeft((985 + $(".image_carousel_snap").scrollLeft()));
}
document.move_photos_to_position = function(position) {
	if (position == 0){
		$(".image_carousel_snap").scrollLeft(0);
	}else{
		var current_image_position = $(".listing_photo_snap")[position - 1].offsetLeft;
		if (current_image_position <= (($(".image_carousel_snap").scrollLeft() + $(".photo_pad").width()) + 10) && current_image_position >= (($(".image_carousel_snap").scrollLeft() + $(".photo_pad").width()) - 10)){
			console.log("clicked photo in view");
		}else {
			if (window.innerWidth <= 541 && window.innerWidth > 0){
				var image_position = $(".listing_photo_snap")[position - 1].offsetLeft - $(".photo_pad").width();
			}else{
				var image_position = $(".listing_photo_snap")[position - 1].offsetLeft - $(".photo_pad").width() - 4;
			}
			$(".image_carousel_snap").scrollLeft(image_position);
		}
	}
}
document.display_on_map = function(nearby){ 
	$("#zenbu_items").html(" ");
	if (nearby == "false"){
		console.log("error_parsing_POI")
	}else{
		document.map_poi_markers = [];
		for(var item_index in nearby) {
			$("#zenbu_items").append("<div class=\"zenbu_item\">" + nearby[item_index]["name"] + "(" + nearby[item_index]["tags"] + ")</div>");

			var propertypoint = new google.maps.LatLng(nearby[item_index]["lat"], nearby[item_index]["long"]);

			document.map_poi_markers.push(new google.maps.Marker({ position: propertypoint, map: document.map, icon: "https://maps.google.com/mapfiles/kml/shapes/info-i_maps.png"}));

			for(var item_index in document.map_poi_markers) {
				if (item_index != 0){
					document.map_poi_markers[item_index].visible = false;
					document.map_poi_position = 0;
					$("#zenbu_scroll").scrollTop(0);
				}
			}
		}
	}
}
function move_poi_pointer(){
	if(document.map_poi_markers.length > 0){
		var next_poi, showing_poi;
		if((document.map_poi_markers.length - 1) == (document.map_poi_position)) {
			console.log("resetting poi")
			next_poi = 0;
			showing_poi = document.map_poi_position;
			document.map_poi_position = 0;
			$("#zenbu_scroll").scrollTop(0);
		}else{
			next_poi = document.map_poi_position + 1;
			showing_poi = document.map_poi_position;
			document.map_poi_position = (document.map_poi_position + 1);
			$("#zenbu_scroll").scrollTop($("#zenbu_scroll").scrollTop() + 30);
		}

		document.map_poi_markers[next_poi].setMap(document.map);
		document.map_poi_markers[showing_poi].setMap(document.map);

		document.map_poi_markers[next_poi].setVisible(true);
		document.map_poi_markers[showing_poi].setVisible(false);

	}
}
document.addEventListener("turbolinks:load", function() {
	if (document.poi_mover_running == true){

	}else{
		setInterval(move_poi_pointer, 3000);
		document.poi_mover_running = true;
	}
});

window.addEventListener("resize", function() {
    document.window_resized_resize_photos();
});

document.addEventListener("turbolinks:load", function() {
  $('#photos_sort').sortable({
    axis: 'y',
    handle: '.handle',
    update:function(){return $.post($('#photos_sort').data('update-url'), $('#photos_sort').sortable('serialize'))}
  })
});
